import { useMemo } from "react";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";
import { runtimeConfig } from "@src/runtime-config";
import { useCachedTokenProvider } from "@api/use-cached-token-provider";

interface UseProgressApiClientProps {
  /**
   * The project ID to use for initializing progress API client.
   */
  projectId: string;
}

/**
 * Initializes the progress API client and returns a memoized instance
 */
export function useProgressApiClient({
  projectId,
}: UseProgressApiClientProps): ProgressApiClient {
  const tokenProvider = useCachedTokenProvider({
    projectId,
  });

  return useMemo(
    () =>
      new ProgressApiClient(
        projectId,
        tokenProvider,
        runtimeConfig.urls.progressApiUrl
      ),
    [tokenProvider, projectId]
  );
}
