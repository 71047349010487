import { useMemo, useState, useEffect } from "react";
import { Box, ButtonGroup } from "@mui/material";
import { selectProjectLoadingState } from "@faro-lotv/project-source";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { BaseProjectProps } from "@custom-types/project-types";
import { DataManagementTable } from "@pages/project-details/project-data-management/data-management-table";
import { StepState, WorkflowState, StepperIndices } from "@pages/project-details/project-data-management/data-management-types";
import { DataManagementTitle } from "@pages/project-details/project-data-management/data-mangement-title";
import {
  hasRegisterErrorSelector,
  isRegisteredSelector,
  isRegisteringSelector,
  registeredDataSelector,
} from "@pages/project-details/project-data-management/registered-data/registered-data-selectors";
import { publishedDataSelector } from "@pages/project-details/project-data-management/published-data/published-data-selectors";
import { ImportData } from "@pages/project-details/project-data-management/import-data/import-data";
import {
  isProcessedSelector,
  isProcessingSelector,
  uploadedDataSelector,
  hasProcessErrorSelector,
  addScanTasksSelector,
} from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import {
  getProcessProgress,
  getRegisterProgress,
  getTableItemsFromEntities,
  getTableItemsFromTasks,
  getUploadedIdsMap,
  getUploadProgress,
} from "@pages/project-details/project-data-management/data-management-utils";
import {
  fetchingStatusAllRegistrationRevisionsSelector,
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedAllRegistrationRevisionsSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
  hasFetchCaptureTreeDataErrorSelector,
  captureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { currentUserSelector } from "@store/user/user-selector";
import { sphereColors } from "@styles/common-colors";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { FaroTextButton } from "@components/common/faro-text-button";
import { colorConst } from "@styles/common-colors";
import { DataManagementDropzone } from "@pages/project-details/project-data-management/data-management-dropzone";
import { DataManagementStepper } from "@pages/project-details/project-data-management/data-management-stepper/data-mangement-stepper";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { FaroAlert } from "@components/common/faro-alert";
import { useBackgroundTasksTracker } from "@hooks/background-tasks/use-background-tasks-tracker";
import { APITypes } from "@stellar/api-logic";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { MAX_CONCURRENT_UPLOADS } from "@context-providers/file-upload/upload-manager";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { CoreFileUploader, MAX_CONCURRENT_CHUNKS, UploadEndpoint } from "@utils/core-file-uploader";

/**
 * The main page for the Staging Area workflow, including the progress stepper and the scan table.
 * The code is unfortunately complex and "ugly" since the basic data elements weren't constructed to work
 * well with such a workflow and the user can also add additional data later, resetting the workflow while
 * older data still exists.
 */
export function DataManagementWorkflow({project}: BaseProjectProps): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const { uploadManager } = useFileUploadContext();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const captureTree = useAppSelector(captureTreeForMainRevisionSelector);

  // Start listening for background tasks.
  useBackgroundTasksTracker({ projectId: project.id.toString() });
  const sdbBackgroundsTasks = useAppSelector(sdbBackgroundTasksSelector);

  // ##### Fetch status ##### //

  const hasFetchedCaptureTree = useAppSelector(hasFetchedCaptureTreeForMainRevisionSelector);
  // fetchingStatusPrepared and hasFetchedAllRegistrationRevisions are also used for publishedData.
  const fetchingStatusRegistered = useAppSelector(fetchingStatusAllRegistrationRevisionsSelector);
  const hasFetchedAllRegistrationRevisions = useAppSelector(hasFetchedAllRegistrationRevisionsSelector);
  const fetchingStatusUploaded = useAppSelector(fetchingStatusCaptureTreeForMainRevisionSelector);
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);
  const hasFetchCaptureTreeDataError = useAppSelector(hasFetchCaptureTreeDataErrorSelector);

  const isFetchingForTheFirstTime = !hasFetchedCaptureTree &&
    !hasFetchedAllRegistrationRevisions &&
    (fetchingStatusUploaded === FetchingStatus.pending ||
     fetchingStatusRegistered === FetchingStatus.pending ||
     fetchingStatusIElements === "loading");

  const hasFailedToFetchUploadedData = fetchingStatusUploaded === FetchingStatus.rejected ||
    fetchingStatusIElements === "failed";
  const hasFailedToFetchRegisteredData = fetchingStatusRegistered === FetchingStatus.rejected;

  // ##### Upload tasks ##### //

  const uploadTasksAll = useAppSelector(uploadTasksSelector);
  // uploadTasksSelector also returns tasks for other projects and needs to be filtered.
  // That means we also can't use hasInProgressUploadTasksSelector since it also uses tasks of other projects.
  const uploadTasks = uploadTasksAll.filter((task) => task.context?.projectId === project.id);
  // Use the same code as in hasInProgressUploadTasksSelector here:
  let isUploading = uploadTasks.some((task) => [
    BackgroundTaskState.created,
    BackgroundTaskState.scheduled,
    BackgroundTaskState.started,
  ].includes(task.status));

  // ##### Uploaded data ##### //

  const uploadedEntities = useAppSelector(uploadedDataSelector);
  const isProcessing = useAppSelector(isProcessingSelector);
  const isProcessed = useAppSelector(isProcessedSelector);
  const hasProcessError: boolean = useAppSelector(hasProcessErrorSelector);

  const addScanTasks = useAppSelector(addScanTasksSelector);
  const scanTaskNewest = addScanTasks.length ? addScanTasks[0] : undefined;
  const timeUpload = useMemo(() => {
    return scanTaskNewest ? (new Date(scanTaskNewest.createdAt)).getTime() : 0;
  }, [scanTaskNewest]);

  // ##### Registered data ##### //

  const registrationRevisions = useAppSelector(registeredDataSelector);
  const registrationRevision = registrationRevisions.length ? registrationRevisions[0] : undefined;
  const isRegistering = useAppSelector(isRegisteringSelector);
  const { hasRegisterError, hasBadRegistration } = useAppSelector(hasRegisterErrorSelector);
  const registrationId = registrationRevision ? registrationRevision.id : undefined;
  const timeRegister = useMemo(() => {
    return registrationRevision ? (new Date(registrationRevision.modifiedAt)).getTime() : 0;
  }, [registrationRevision]);
  const isRegistered = useAppSelector(isRegisteredSelector) && 0 < timeRegister && timeUpload <= timeRegister;

  // ##### Published data ##### //

  const publishedRevisions = useAppSelector(publishedDataSelector);
  const publishedRevision = publishedRevisions.length ? publishedRevisions[0] : undefined;
  const isPublishing: boolean = false;
  const timePublish = useMemo(() => {
    return publishedRevision ? new Date(publishedRevision.modifiedAt).getTime() : 0;
  }, [publishedRevision]);
  const isPublished = !!publishedRevision && 0 < timePublish && timeUpload <= timePublish && timeRegister <= timePublish;
  const hasPublishError: boolean = false;

  // ##### Table items (Part 1) ##### //

  // We don't want to show table entries for (finished) upload tasks for which there's already a member in the
  // uploaded data array.
  const uploadedIdsMap: { [key: APITypes.UUID]: boolean } = useMemo(() => {
    return getUploadedIdsMap(uploadedEntities);
  }, [uploadedEntities]);

  const { tableItemsFromTasks, isUploadingFromTasks, hasUploadErrorFromTasks } = useMemo(() => {
    return getTableItemsFromTasks(uploadTasks, uploadedIdsMap, currentUser);
  }, [uploadTasks, uploadedIdsMap, currentUser]);

  // ##### Upload ##### //

  isUploading = isUploading || isUploadingFromTasks;
  const isUploaded = !isUploading && 0 < uploadedEntities.length;

  // state to manage the close button on uploading alert box
  const [isUploadingAlertOpen, setIsUploadingAlertOpen] = useState<boolean>(true);

  // each time a new scan is uploaded the isUploadingAlertOpen flag should be marked to true
  useEffect(() => {
    if (isUploading) {
      setIsUploadingAlertOpen(true);
    }
  }, [isUploading]);

  // ##### Dev features ##### //

  // Allow to set the maximum number of concurrent file uploads.
  const [maxConcurrentUploads, setMaxConcurrentUploads] = useState<number>(0);
  // Allow to set the maximum number of concurrent chunk uploads per file.
  const [maxConcurrentChunks, setMaxConcurrentChunks] = useState<number>(0);
  // Allow to set the upload endpoint, overriding the auto-detection.
  const [uploadEndpoint, setUploadEndpoint] = useState<UploadEndpoint>("auto");

  function promptMaxConcurrentUploads(): void {
    // For a dev feature, "prompt" is good enough.
    const str = window.prompt(
      "Enter the maximum number of concurrent file uploads and chunk uploads per file (integer greater than 0):\n\n" +
        "files, chunks",
      `${maxConcurrentUploads || MAX_CONCURRENT_UPLOADS}, ${maxConcurrentChunks || MAX_CONCURRENT_CHUNKS}`
    );
    if (str) {
      const [files, chunks] = str.trim().split(/[^0-9.]+/).map((s) => parseFloat(s));
      uploadManager.setMaxConcurrentUploads(files, chunks, /* manual */ true);
      // If the function didn't throw, it worked.
      setMaxConcurrentUploads(files);
      setMaxConcurrentChunks(chunks);
    }
  }

  function promptUploadEndpoint(): void {
    let str = window.prompt(
      "Enter the desired upload endpoint:\n\n" +
        "a = auto | f = frontdoor | s = storage",
      uploadEndpoint
    );
    if (str) {
      if (str.startsWith("a")) {
        str = "auto";
      } else if (str.startsWith("f")) {
        str = "frontdoor";
      } else if (str.startsWith("s")) {
        str = "storage";
      }
      CoreFileUploader.setUploadEndpoint(str as UploadEndpoint);
      // If the function didn't throw, it worked.
      setUploadEndpoint(str as UploadEndpoint);
    }
  }

  // ##### Determine the state ##### //

  // Determine the overall state.
  // The order should support uploading additional scans after later states have already been reached.
  // So the progress must be set to e.g. uploading although there already is e.g. published data.
  let state: WorkflowState = "start";
  let iActiveStep = StepperIndices.upload;

  if (isUploading) {
    state = "uploading";
    iActiveStep = StepperIndices.upload;
  } else if (hasUploadErrorFromTasks) {
    state = "uploadError";
    iActiveStep = StepperIndices.upload;
  } else if (isProcessing) {
    state = "processing";
    iActiveStep = StepperIndices.process;
  } else if (isRegistering) {
    state = "registering";
    iActiveStep = StepperIndices.register;
  } else if (hasRegisterError) {
    state = "registerError";
    iActiveStep = StepperIndices.register;
  } else if (hasBadRegistration) {
    state = "registerBadResult";
    iActiveStep = StepperIndices.register;
  } else if (isPublishing) {
    state = "publishing";
    iActiveStep = StepperIndices.publish;
  } else if (hasPublishError) {
    state = "publishError";
    iActiveStep = StepperIndices.publish;
  } else if (isPublished) {
    state = "published";
    iActiveStep = StepperIndices.publish;
  } else if (isRegistered) {
    state = "registered";
    iActiveStep = StepperIndices.publish;
  } else if (hasProcessError) {
    state = "processError";
    iActiveStep = StepperIndices.process;
  } else if (isProcessed) {
    state = "processed";
    iActiveStep = StepperIndices.register;
  } else if (isUploaded) {
    state = "uploaded";
    iActiveStep = StepperIndices.process;
  }

  // Determine the state of the stepper icons.
  let uploadStepState: StepState = "todo";
  if (StepperIndices.process <= iActiveStep) {
    uploadStepState = "done";
  } else if (state === "uploading") {
    uploadStepState = "active";
  } else if (state === "uploadError") {
    uploadStepState = "error";
  }

  let processStepState: StepState = "todo";
  if (StepperIndices.register <= iActiveStep) {
    processStepState = "done";
  } else if (state === "processing") {
    processStepState = "active";
  } else if (state === "processError") {
    processStepState = "error";
  }

  let registerStepState: StepState = "todo";
  if (StepperIndices.publish <= iActiveStep) {
    registerStepState = "done";
  } else if (state === "registering") {
    registerStepState = "active";
  } else if (state === "registerError" || state === "registerBadResult") {
    registerStepState = "error";
  }

  let publishStepState: StepState = "todo";
  if (state === "published") {
    publishStepState = "done";
  } else if (state === "publishing") {
    publishStepState = "active";
  } else if (state === "publishError") {
    publishStepState = "error";
  }

  const isInProgress = state === "uploading" || state === "processing" || state === "registering" || state === "publishing";
  // Hide or disable the upload buttons while the data is processed in some way.
  const uploadBtnDisabledTooltip = isInProgress ?
    "Uploading additional data is disabled during data processing." :
    undefined;

  // Check if the CancelImportButton is to be disabled, other states to be evaluated later.
  const isCancelImportBtnDisabled = state !== "uploading";

  // ##### Table items (Part 2) ##### //

  // We need to know if state is "published" before being able to determine the table items based on uploadedEntities.
  const tableItemsFromUploads = useMemo(() => {
    return getTableItemsFromEntities(state, uploadedEntities);
  }, [state, uploadedEntities]);

  const tableItems = useMemo(() => {
    return [ ...tableItemsFromTasks, ...tableItemsFromUploads ];
  }, [tableItemsFromTasks, tableItemsFromUploads]);

  // ##### Progress values ##### //

  const uploadProgress = useMemo(() => {
    return getUploadProgress(state, uploadTasks);
  }, [state, uploadTasks]);

  const processProgress = useMemo(() => {
    return getProcessProgress(state, captureTree, sdbBackgroundsTasks);
  }, [state, captureTree, sdbBackgroundsTasks]);

  const registerProgress = useMemo(() => {
    return getRegisterProgress(state, registrationRevision);
  }, [state, registrationRevision]);

  // ##### Dropzone page and error page ##### //

  const isEmpty = tableItems.length === 0 && !isFetchingForTheFirstTime;
  const shouldShowError = isEmpty &&
    (hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData || hasFetchCaptureTreeDataError);
  const shouldShowFullDropzone = isEmpty && !shouldShowError && !!project;
  const shouldShowHiddenDropzone = !shouldShowFullDropzone && !isInProgress && !!project && !isUploadDialogOpen;
  const shouldShowMainContent = !shouldShowError && !shouldShowFullDropzone;

  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const activityUrl = window.location.pathname.replace("/data-management", "/activity") + window.location.search;

  const isUploadBtnDisabled = (isFetchingForTheFirstTime || isInProgress);

  // ##### JSX element ##### //

  return (
    <div>
      <div>
        <div style={{ float: "left" }}>
          <DataManagementTitle isEmpty={isEmpty} />
        </div>
        { shouldShowMainContent &&
          <div style={{ float: "right" }}>
            <ImportData
              project={project}
              uploadedIdsMap={uploadedIdsMap}
              isUploadDialogOpen={isUploadDialogOpen}
              setIsUploadDialogOpen={setIsUploadDialogOpen}
              isUploadBtnVisible={!isUploadBtnDisabled}
              isUploadBtnDisabled={isUploadBtnDisabled}
              uploadBtnDisabledTooltip={uploadBtnDisabledTooltip}
              isCancelImportBtnDisabled={isCancelImportBtnDisabled}
            />
          </div>
        }
      </div>

      {isDevModeEnabled && (
        <ButtonGroup variant="outlined" sx={{ paddingTop: "5px" }}>
          <FaroTextButton
            to={activityUrl}
            target="_blank"
            size="small"
            sx={{
              color: colorConst.devFeature,
            }}
            onClick={() => {
              // do nothing, we use "to" to open a new tab
            }}
          >
            Activity (dev)
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{
              color: colorConst.devFeature,
            }}
            onClick={promptMaxConcurrentUploads}
          >
            Set max concurrent uploads (Dev) =
            {maxConcurrentUploads > 0 ? ` ${maxConcurrentUploads} files | ${maxConcurrentChunks} chunks/file` : " auto"}
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{
              color: colorConst.devFeature,
            }}
            onClick={promptUploadEndpoint}
          >
            Upload endpoint = {uploadEndpoint}
          </FaroTextButton>
        </ButtonGroup>
      )}

      <Box
        data-testid="sa-workflow"
        sx={{
          marginTop: "14px",
          border: shouldShowFullDropzone ? undefined : `1px solid ${sphereColors.gray200}`,
          display: "inline-block",
          width: "100%",
        }}
      >

      {shouldShowError && (
        // Since we don't know in which state the project is, we don't show the ImportDataButton here.
        <EmptyPage
          title="Error"
          subtitle="Failed to fetch the uploaded data for this project! Please reload the page and try again."
          icon={FailedIcon}
        />
      )}

      {(isUploading && isUploadingAlertOpen) && (
        <FaroAlert
          severity="warning"
          onClose={() => setIsUploadingAlertOpen(false)}
        >
          <strong style={{marginRight: 15}}>Uploading data...</strong>
          Please keep your browser open and stay on this page.
          Leaving or closing the browser may interrupt the upload.
        </FaroAlert>
      )}

      {(shouldShowFullDropzone || shouldShowHiddenDropzone) && (
        <DataManagementDropzone
          project={project}
          uploadedIdsMap={uploadedIdsMap}
          isStandalone={shouldShowFullDropzone}
        />
      )}

      {shouldShowMainContent && (
        <Box sx={{ background: "white" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              paddingX: "30px",
              paddingTop: "30px",
              paddingBottom: "45px",
              fontWeight: "bold",
            }}
          >
            <div style={{ width: "100%" }}>
              <span style={{ marginRight: "100px", fontSize: "larger", float: "left" }}>
                Blink scans
              </span>

              <DataManagementStepper
                state={state}
                uploadStepState={uploadStepState}
                processStepState={processStepState}
                registerStepState={registerStepState}
                publishStepState={publishStepState}
                uploadProgress={uploadProgress}
                processProgress={processProgress}
                registerProgress={registerProgress}
                setIsUploadDialogOpen={setIsUploadDialogOpen}
                registrationId={registrationId}
                projectId={project.id}
                isUploadBtnVisible={!isFetchingForTheFirstTime && !isInProgress}
                userId={currentUser?.id ?? ""}
              />
            </div>
          </Box>
          <Box sx={{ paddingX: "30px", paddingBottom: "30px" }}>
            <DataManagementTable tableItems={tableItems} isFetchingForTheFirstTime={isFetchingForTheFirstTime} />
          </Box>
        </Box>
      )}
      </Box>
    </div>
  );
}
