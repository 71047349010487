import SparkMD5 from "spark-md5";

interface QueuedChunk {
  /** Slice of the original file, with bytes [startByte, endByte - 1]. */
  blobData: ArrayBuffer;
  startByte: number;
  endByte: number;
}

/**
 * Chunked MD5 calculation based on SparkMD5, correctly handling out-of-order chunks by queueing them.
 */
export class ChunkedMD5 {
  #spark = new SparkMD5.ArrayBuffer();
  #queue: QueuedChunk[] = [];
  #nextStartByte = 0;

  /**
   * Append a chunk for MD5 calculation.
   * The chunks can be added out-of-order, but the missing chunk(s) should be added soon to avoid increased
   * memory usage due to queueing.
   *
   * @param blobData Chunk (binary data).
   * @param startByte Start byte of the chunk in the file.
   * @throws {Error} When trying to add a previous chunk again.
   */
  public append(blobData: ArrayBuffer, startByte: number): void {
    const endByte = startByte + blobData.byteLength;

    if (startByte === this.#nextStartByte) {
      this.#spark.append(blobData);
      this.#nextStartByte = endByte;
      if (this.#queue.length > 0) {
        this.#processQueue();
      }
    } else if (startByte < this.#nextStartByte) {
      throw new Error(`ChunkedMD5: Trying to add earlier chunk with startByte ${startByte}; ` +
        `expected instead >= ${this.#nextStartByte}.`);
    } else {
      this.#queue.push({ blobData, startByte, endByte });
    }
  }

  /**
   * Finish the MD5 calculation and return the hash.
   * @returns Hex string with MD5 hash.
   * @throws {Error} If chunks in between were missing.
   */
  public end(): string {
    if (this.#queue.length > 0) {
      throw new Error(`ChunkedMD5: Cannot calculate hash. Missing chunk with startByte ${this.#nextStartByte}.`);
    }
    return this.#spark.end();
  }

  /**
   * Process the queued chunks, adding as many as possible to the MD5 calculation and removing them from the queue.
   */
  #processQueue(): void {
    let chunk: QueuedChunk | undefined;
    // eslint-disable-next-line no-constant-condition -- We use a "break" below.
    while (true) {
      chunk = undefined;
      for (let i = 0; i < this.#queue.length; i++) {
        if (this.#queue[i].startByte === this.#nextStartByte) {
          chunk = this.#queue[i];
          this.#queue.splice(i, 1);
          break;
        }
      }

      if (!chunk) {
        break;
      }
      this.#spark.append(chunk.blobData);
      this.#nextStartByte = chunk.endByte;
    }
  }
}
