import { Box } from "@mui/material";
import { DataManagementLayout } from "@pages/project-details/project-data-management/data-management-layout";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { fetchProjectDetails } from "@store/projects/projects-slice-thunk";
import { AuthenticatedRoute } from "@router/authenticated-route";
import {
  isProjectArchiveSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { isBetaTestingEnabledOrNotProdSelector } from "@store/app/app-selector";
import { ForbiddenPage } from "@pages/forbidden-page";
import { useDataManagement } from "@hooks/data-management/use-data-management";
import { useMembersUtils } from "@hooks/use-member-utils";
import { DataManagementWorkflow } from "@pages/project-details/project-data-management/data-management-workflow";
import { DataManagementProvider } from "@context-providers/data-management/data-management-context";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";

/** Renders project data management page */
export function ProjectDataManagement({
  projectId,
}: BaseProjectIdProps): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const project = useAppSelector(selectedProjectSelector);
  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );
  const isProjectArchived = useAppSelector(
    isProjectArchiveSelector(project?.id ?? "")
  );
  useDataManagement({ projectId });
  const { companyMembers, projectMembers } = useMembersUtils();

  // Fetches project details
  useEffect(() => {
    if (companyId && !project) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchProjectDetails({ coreApiClient, companyId, projectId }));
    }
  }, [companyId, coreApiClient, dispatch, projectId, project]);

  if (!isBetaTestingEnabled || isProjectArchived) {
    return <ForbiddenPage />;
  }

  return (
    <AuthenticatedRoute
      key={"data-management-page"}
      requiredPermissionProjectLevel={
        RequiredPermissionProjectLevelName.canViewDataManagement
      }
    >
      <DataManagementProvider
        projectId={projectId}
        companyMembers={companyMembers}
        projectMembers={projectMembers}
      >
        <DataManagementLayout>
          <Box
            data-testid="sa-container"
            sx={{
              width: "100%",
            }}
          >
            {/* There's always a selected project, so this check is always true. */}
            {project && <DataManagementWorkflow project={project} />}
          </Box>
        </DataManagementLayout>
      </DataManagementProvider>
    </AuthenticatedRoute>
  );
}
