import { isCloudRegistrationTask } from "@custom-types/sdb-background-tasks-type-guards";
import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { createSelector } from "@reduxjs/toolkit";
import { allRegistrationRevisionsSelector } from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import {
  hasRegisterError,
  hasBadRegistration,
  isRegistered,
  isRegistering,
  isRegisteringOrRegistered,
} from "@pages/project-details/project-data-management/registered-data/registered-data-utils";

/**
 * @returns All RegisteredData entities currently getting registered or already registered,
 * sorted from newest creation date to oldest.
 */
export const registeredDataSelector: (state: RootState) => RegisteredData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const registrations = allRegistrationRevisionsSelector(state);
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const cloudRegistrationTasks = backgroundTasks.filter(isCloudRegistrationTask);

      const entities: RegisteredData[] = registrations.map((registration) => {
        const task = cloudRegistrationTasks.find(
          (cloudRegistrationTask) =>
            cloudRegistrationTask.context?.elementId === registration.id
        );

        return {
          ...registration,
          task,
        };
      });

      return entities
        .filter(isRegisteringOrRegistered)
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/**
 * @returns true if the newest RegisteredData revision is getting registered.
 */
export const isRegisteringSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = registeredDataSelector(state);
      return 0 < entities.length && isRegistering(entities[0]);
    }
  );

/**
 * @returns true if the newest RegisteredData revision has been registered.
 */
export const isRegisteredSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = registeredDataSelector(state);
      return 0 < entities.length && isRegistered(entities[0]);
    }
  );

/**
 * @returns an object of two booleans depending if the RegisteredData revision has a task that failed to finish
 * or has succeeded but has bad registration.
 */
export const hasRegisterErrorSelector: (state: RootState) => { hasRegisterError: boolean; hasBadRegistration: boolean } =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = registeredDataSelector(state);
      const hasEntities = entities.length > 0;
      return {
        hasRegisterError: hasEntities && hasRegisterError(entities[0]),
        hasBadRegistration: hasEntities && hasBadRegistration(entities[0]),
      };
    }
  );
