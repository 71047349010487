// eslint-disable-next-line no-restricted-imports -- This is the single place where we instantiate a CoreApiTokenManager.
import { CoreApiTokenManager, TokenProvider } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { CLIENT_ID } from "@api/client-id";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useMemo } from "react";

/** Map from stringified project ID to cached token provider. */
const tokenProviderMap: Record<string, TokenProvider> = {};

/**
 * Returns a token provider for the given project ID. The instance is cached, so that the /auth/token endpoint
 * will be called only once per project as long as the token is valid.
 * @param projectId
 * @returns Function that returns a Promise for a token.
 */
export function getCachedTokenProvider(projectId: APITypes.ProjectId): TokenProvider {
  projectId = projectId.toString();
  if (!tokenProviderMap[projectId]) {
    const coreApiUrl = new URL(runtimeConfig.urls.apiBaseUrl);
    const tokenManager = new CoreApiTokenManager(coreApiUrl, projectId, CLIENT_ID);
    tokenProviderMap[projectId] = tokenManager.getToken.bind(tokenManager);
  }
  return tokenProviderMap[projectId];
}

/**
 * Returns a token provider for the given project ID. The instance is cached, so that the /auth/token endpoint
 * will be called only once per project as long as the token is valid.
 * @param projectId
 * @returns Function that returns a Promise for a token.
 */
export function useCachedTokenProvider({
  projectId,
}: BaseProjectIdProps): TokenProvider {
  // Make sure the client is only created once, unless projectId changes.
  return useMemo(
    () => getCachedTokenProvider(projectId),
    [projectId]
  );
}
