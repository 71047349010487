import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import { Markup } from "@custom-types/project-markups-types";
import { GUID } from "@faro-lotv/foundation";
import {
  MarkupContextType,
  MarkupProviderProps,
  TabName,
} from "@context-providers/markup/markup-context-types";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";

/** Create the context for the markup side panel */
const MarkupContext = createContext<MarkupContextType | undefined>(undefined);

/**
 * Component that provides the context for the markup side panel.
 * This provider is to avoid the prop drilling that we are suffering
 *
 * @param children The child components to be wrapped by the provider.
 * @param projectId The ID of the project.
 * @returns JSX element representing the provider.
 */
export function MarkupProvider({
  children,
  projectId,
  markups: initialsStateMarkups,
}: PropsWithChildren<MarkupProviderProps>): JSX.Element | null {
  const [markups, setMarkups] = useState<Markup[]>(initialsStateMarkups);
  const [selectedMarkup, setSelectedMarkup] = useState<Markup>();
  const [isMarkupUpdating, setIsMarkupUpdating] = useState(false);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [isOpenMarkupSidePanel, setIsOpenMarkupSidePanel] =
    useState<boolean>(false);
  const [selectedMarkupId, setSelectedMarkupId] = useState<GUID>();
  const [activeTab, setActiveTab] = useState<TabName>("Info");
  const [isFirstInteraction, setIsFirstInteraction] = useState<boolean>(false);

  const { canEditProjectMarkups } = useHasUserValidPermissionProjectLevel();

  useEffect(() => {
    if (!isFirstInteraction && initialsStateMarkups.length) {
      setMarkups(initialsStateMarkups);
      setIsFirstInteraction(true);
    }
  }, [isFirstInteraction, initialsStateMarkups]);

  /**
   * Updates the selected markup to be displayed in the side panel.
   * This function is used to update the state of the selected markup in the component.
   *
   * @param markup The markup object representing the selected markup.
   */
  function updateSelectedMarkup(markup?: Markup): void {
    setSelectedMarkup(markup ?? undefined);
  }

  /**
   * Updates the state of the side panel's open status.
   * This function sets whether the side panel is open or closed.
   *
   * @param isOpen A boolean indicating whether the side panel should be open (true) or closed (false).
   */
  function updateIsSidePanelOpen(isOpen: boolean): void {
    setIsOpenMarkupSidePanel(isOpen);
  }

  /**
   * Updates the ID of the currently selected markup in the table.
   *
   * This function sets the ID of the currently selected markup, which is used to
   * highlight the corresponding row in the table and apply a different background
   * color, indicating that the row is active. Passing `undefined` removes the selection.
   *
   * @param markupId - The ID of the markup to be selected, or `undefined` to remove the selection.
   */
  function updateSelectedMarkupId(id: GUID | undefined): void {
    setSelectedMarkupId(id);
  }

  /**
   * Updates the active tab to the specified tab.
   *
   * @param activeTab The name of the tab to set as active.
   */
  function updateActiveTab(activeTab: TabName): void {
    setActiveTab(activeTab);
  }

  /** Returns true if there is any update on markup in progress */
  function isMarkupUpdateInProgress(): boolean {
    return isMarkupUpdating || isAttachmentUploading;
  }

  /**
   *  Update the markups list
   *
   * @param markups markups list
   * @returns
   */
  function updateMarkups(markups: Markup[]): void {
    return setMarkups(markups);
  }

  return (
    <MarkupContext.Provider
      value={{
        projectId,
        selectedMarkup,
        updateSelectedMarkup,
        isOpenMarkupSidePanel,
        updateIsSidePanelOpen,
        selectedMarkupId,
        updateSelectedMarkupId,
        isMarkupUpdateInProgress,
        isAttachmentUploading,
        setIsAttachmentUploading,
        setIsMarkupUpdating,
        hasPermissionToEditMarkup: canEditProjectMarkups,
        activeTab,
        updateActiveTab,
        markups,
        updateMarkups,
      }}
    >
      {children}
    </MarkupContext.Provider>
  );
}

/**
 * Hook to use the markup context.
 *
 * @returns The markup context, containing information about the selected markup,
 * side panel state, and project ID.
 * @throws An error if the hook is used outside of a MarkupProvider.
 */
export function useMarkupContext(): MarkupContextType {
  const context = useContext(MarkupContext);

  if (!context) {
    throw new Error("useMarkupContext must be used within a MarkupProvider");
  }

  return context;
}
