import {
  BaseProjectIdProps,
  BaseUserIdProps,
} from "@custom-types/sdb-company-types";
import { RegistrationApiClient } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { CLIENT_ID } from "@api/client-id";
import { getCachedTokenProvider } from "@api/use-cached-token-provider";

/**
 * Returns an instance of the registration api client.
 *
 * @returns RegistrationApiClient instance
 */
export function getRegistrationApiClient({
  projectId,
  userId,
}: BaseProjectIdProps & BaseUserIdProps): RegistrationApiClient {
  const tokenProvider = getCachedTokenProvider(projectId);

  return new RegistrationApiClient({
    projectId: projectId.toString(),
    userId,
    tokenProvider,
    baseUrl: runtimeConfig.urls.registrationApiUrl,
    clientId: CLIENT_ID,
  });
}
