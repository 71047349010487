import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { TabProps, TabsWithRoutes } from "@components/common/tabs-with-routes";
import { UsersTabs } from "@router/route-params";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { Teams } from "@pages/members/teams/teams";
import { MembersPage } from "@pages/members/members-page";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { Stack, Typography } from "@mui/material";
import { FaroBetaBadge } from "@components/common/faro-beta-badge";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { useAppSelector } from "@store/store-helper";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { SdbCompany } from "@custom-types/sdb-company-types";

/** Content that will have the logic for the users tabs */
export function MembersWorkspace(): JSX.Element {
  const { usersTab } = useAppParams();
  const { companyId } = useAppParams();
  const { canViewTeams } = useHasUserValidRoleCompanyLevel();
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);

  if (!companyId || !usersTab || !Object.values(UsersTabs).includes(usersTab)) {
    return <NotFoundPage />;
  }

  const usersTabs: TabProps<UsersTabs, SdbCompany | null>[] = [
    {
      label: "Members",
      route: UsersTabs.members,
      content: () => <MembersPage />,
      loadingContent: <div />,
    },
    {
      label: capitalizeFirstLetter(TEAMS_DISPLAY_NAME),
      route: UsersTabs.teams,
      content: () => <Teams companyId={companyId} />,
      loadingContent: <div />,
      isBetaFeature: true,
      requiredRoleCompanyLevel: RequiredRoleCompanyLevelName.canViewTeams,
      popover: {
        description: (
          <Typography fontSize={"12px"} fontWeight={"400"}>
            The {capitalizeFirstLetter(TEAMS_DISPLAY_NAME)} feature is currently
            in its beta phase, meaning it is an early release designed to give
            you a sneak peek at its capabilities. While fully functional, you
            may encounter occasional issues as we actively refine it based on
            user feedback to ensure it meets your needs.
          </Typography>
        ),
        title: (
          <Stack flexDirection={"row"} gap={"8px"}>
            {TEAMS_DISPLAY_NAME} <FaroBetaBadge />
          </Stack>
        ),
        shouldShowFeedbackButton: true,
      },
      isHidden: !canViewTeams,
    },
  ];

  return (
    <TabsWithRoutes
      dataTestId="USER_CONTAINER"
      selectedTab={usersTab}
      requiredAttribute={selectedSdbCompany}
      trackingEventPageName={OpenTabEvents.openWorkspaceUsersTab}
      tabs={usersTabs}
    />
  );
}
