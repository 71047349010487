import { useCoreApiClient } from "@api/use-core-api-client";
import { IReactChildrenOnly } from "@custom-types/types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setNumUnreadMsg } from "@store/ui/ui-slice";
import { loggedInUserSelector } from "@store/user/user-selector";
import {
  HolobuilderCookieManager,
  INTERCOM_COOKIE,
} from "@utils/cookie-manager/cookie-manager-utils";
import { useEffect } from "react";

const oneSecondInMilliseconds = 1000;

/**
 * A loader that updates the Intercom-instance with the logged in user data. This is necessary to identify
 * users in Intercom and to distinguish them from (not logged in) visitors
 *
 * We assume that Intercom is already initialized on the window object or will be. Currently this is done
 * via Google Tag Manager. If this changes at some point in the future, this loader also needs to change
 * (or be replaced by a provider)
 */
export function IntercomLoader({ children }: IReactChildrenOnly): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const loggedInUser = useAppSelector(loggedInUserSelector);
  const dispatch = useAppDispatch();

  // This useEffect updates the user data in Intercom when the logged in user changes
  useEffect(() => {
    function waitForIntercom(): Promise<void> {
      return new Promise((res) => {
        setTimeout(() => {
          if (typeof window.Intercom !== "undefined") {
            res();
          } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
            waitForIntercom().then(() => {
              res();
            });
          }
        }, oneSecondInMilliseconds);
      });
    }

    async function updateIntercom(
      loggedInUser: SphereDashboardAPITypes.IUserDetails
    ): Promise<void> {
      // Ensure that Intercom is initialized (via Google TagManager) and then request the user hash
      // to update user data in Intercom
      await waitForIntercom();

      if (window.Intercom && window.intercomSettings) {
        const data = await coreApiClient.V3.getIntercomHash();

        window.intercomSettings = {
          ...window.intercomSettings,
          name: loggedInUser.name,
          email: loggedInUser.mailAddress,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Type from Intercom
          created_at:
            loggedInUser.accountCreationDate ||
            Math.floor(Date.now() / oneSecondInMilliseconds),
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Type from Intercom
          user_hash: data.hash,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Type from Intercom
          user_id: data.userId,
        };

        window.Intercom("update", window.intercomSettings);

        window.Intercom("onUnreadCountChange", (numUnreadMsg) => {
          dispatch(setNumUnreadMsg(numUnreadMsg));
        });
      }
    }

    // We need to disable the default button for the Intercom chat as soon as possible since it would otherwise
    // be shown for a fraction of a second during page load before we reach the code after waitForIntercom.
    if (window.Intercom && window.intercomSettings) {
      window.intercomSettings = {
        ...window.intercomSettings,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Type from Intercom
        hide_default_launcher: true,
      };
      window.Intercom("update", window.intercomSettings);
    }

    if (
      HolobuilderCookieManager &&
      HolobuilderCookieManager.isCookieAccepted(INTERCOM_COOKIE) &&
      loggedInUser
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      updateIntercom(loggedInUser);
    }
  }, [coreApiClient, dispatch, loggedInUser]);

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
  return <>{children}</>;
}
