import { useState } from "react";
import { Markup } from "@custom-types/project-markups-types";
import { FaroTextButton } from "@components/common/faro-text-button";
import { sphereColors } from "@styles/common-colors";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { BulkActionEvents, TableType } from "@utils/track-event/track-event-list";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useToast } from "@hooks/use-toast";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { DeleteMarkupDialog } from "@pages/project-details/project-markups/markup-delete-dialog";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { nounPluralize } from "@utils/data-display";
import { IElementAttachment } from "@faro-lotv/ielement-types";
import { cloneDeep, findIndex } from "lodash";

interface Props extends BaseProjectIdProps {
  /** The selected attachments to delete */
  selectedAttachments: IElementAttachment[];
  
  /** The selected markup */
  selectedMarkup: Markup;

  /** Function to set the selected attachments */
  setSelectedAttachments: (selectedAttachments: Set<string>) => void;
}

/** Show delete attachments button and dispatch action where the delete dialog will act accordingly */
export function BulkDeleteMarkupAttachments({
    selectedAttachments,
    projectId,
    selectedMarkup,
    setSelectedAttachments,
  }: Props): JSX.Element {

    const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { bulkDeleteAttachmentsFromMarkup } = useProjectMarkupUpdate({ projectId });
    const { showToast } = useToast();
    const { trackEvent } = useTrackEvent();
    const { handleErrorWithToast } = useErrorContext();
    const { markups, updateMarkups } = useMarkupContext();

    /** Delete markup's attachments in bulk */
    async function deleteMarkupAttachmentsInBulk(): Promise<void> {
      trackEvent({
        name: BulkActionEvents.bulkDeleteMarkupAttachment,
        props: {
          dataType: TableType.markupAttachments,
          numberOfEntities: selectedAttachments.length,
        },
      });

      try {
        setIsDeleting(true);
        const response = await bulkDeleteAttachmentsFromMarkup(selectedAttachments);
          if(response && response.failedMutations.length === 0){
            showToast({
              message: "Attachments deleted.", 
              type: "success",
            });
          } else {
            showToast({
              message: `Failed to delete ${nounPluralize({
                counter: response.failedMutations.length,
                word: "Attachment",
              })}`,
              type: "error",
            });
          }
      
      // Remove the deleted attachments from the selected markup and update the markups context
      const newMarkups = cloneDeep(markups);
      const markupIndex = findIndex(newMarkups, { id: selectedMarkup.id });

      if (markupIndex !== -1) {
        const updatedAttachments = selectedMarkup.attachments.filter(
          (attachment) => !response.successMutations.some((deletion) => deletion.elementId === attachment.id)
        );
        newMarkups[markupIndex].attachments = updatedAttachments;
        updateMarkups(newMarkups);
      }
      } catch (error) {
        handleErrorWithToast({
          id: `Delete attachments-${Date.now().toString()}`,
          title: "Failed to delete the annotation's attachments.",
          error: getErrorDisplayMarkup(error),
        });
  
      } finally {
        setIsDeleting(false);
        setShouldShowDialog(false);
        setSelectedAttachments(new Set());
      }
    }
    return (
      <>
        <FaroTextButton
          onClick={() => setShouldShowDialog(true)}
          tooltipText= "Delete attachments"
          sx={{
            fontWeight: 600,
            paddingX: "10px",
            paddingY: "6px",
            width: "60px",
            color: sphereColors.blue500,
          }}
        >
          Delete
        </FaroTextButton>

        {shouldShowDialog && (
          <DeleteMarkupDialog
            dialogContent={{
              title: "Delete attachments?",
              description: "This will remove the selected attachments from your project.",
            }}
            onClose={() => setShouldShowDialog(false)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onConfirm={deleteMarkupAttachmentsInBulk}
            isDeleting={isDeleting}
            shouldShowWarning={false}
          />
        )}
      </>
    );
  }
