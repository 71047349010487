import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocalStorageUtils } from "@utils/local-storage-utils";

export enum DataViewMode {
  /** Display the data in cards */
  cards = "cards",

  /** Display the data in a table */
  list = "list",
}

/**
 * State of the current UI
 */
export type UiState = {
  /** How the data such as projects, groups and users should be displayed */
  dataViewMode: {
    projectsView: DataViewMode;
    groupsView: DataViewMode;
  };

  sidebar: {
    /** Flag whether the sidebar should be shown full size or minified in larger screens */
    isFullSize: boolean;

    /** Flag whether the sidebar is open or closed on mobile screens */
    isOpenOnMobile: boolean;

    /** Flag to decide whether the sidebar should be visible or hidden */
    isVisible: boolean;
  };

  search: {
    /** Store the searchbar input */
    searchText: string;
  };

  /**
   * Flag to hide migrated workspaces from Sphere Legacy.
   * This is only used internally to avoid displaying so many useless workspaces.
   * TODO: Once https://faro01.atlassian.net/browse/ST-1652 is implemented we can remove this.
   */
  isHideMigratedWorkspacesEnabled: boolean;

  /** Number of unread messages in the Intercom chat. */
  numUnreadMsg: number;
};

export const initialState: UiState = {
  dataViewMode: LocalStorageUtils.getJSONItem("data-view-mode") ?? {
    projectsView: DataViewMode.cards,
    groupsView: DataViewMode.cards,
  },

  sidebar: {
    isFullSize:
      LocalStorageUtils.getBooleanItem("is-sidebar-full-size") ?? true,

    isOpenOnMobile: false,

    isVisible: false,
  },

  search: {
    searchText: "",
  },

  // We first check the local storage so that we don't have to provide all the time the query param.
  isHideMigratedWorkspacesEnabled:
    LocalStorageUtils.getBooleanItem("is-hide-migrated-workspaces-enabled") ??
    false,

  numUnreadMsg: 0,
};

/**
 * Slice to access state of ui
 */
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setProjectsDataViewMode(state, action: PayloadAction<DataViewMode>) {
      state.dataViewMode.projectsView = action.payload;
      LocalStorageUtils.setJSONItem("data-view-mode", state.dataViewMode);
    },

    setGroupsDataViewMode(state, action: PayloadAction<DataViewMode>) {
      state.dataViewMode.groupsView = action.payload;
      LocalStorageUtils.setJSONItem("data-view-mode", state.dataViewMode);
    },

    setIsHideMigratedWorkspacesEnabled(state, action: PayloadAction<boolean>) {
      LocalStorageUtils.setBooleanItem(
        "is-hide-migrated-workspaces-enabled",
        action.payload
      );
      state.isHideMigratedWorkspacesEnabled = action.payload;
    },

    setNumUnreadMsg(state, action: PayloadAction<number>) {
      state.numUnreadMsg = action.payload;
    },

    updateSidebar(
      state: UiState,
      action: PayloadAction<Partial<UiState["sidebar"]>>
    ) {
      state.sidebar = { ...state.sidebar, ...action.payload };

      if (action.payload.isFullSize !== undefined) {
        LocalStorageUtils.setBooleanItem(
          "is-sidebar-full-size",
          state.sidebar.isFullSize
        );
      }
    },

    updateSearchbar(
      state: UiState,
      action: PayloadAction<Partial<UiState["search"]>>
    ) {
      state.search = { ...state.search, ...action.payload };
    },

    resetUiState: () => initialState,
  },
});

export const {
  setProjectsDataViewMode,
  setGroupsDataViewMode,
  setIsHideMigratedWorkspacesEnabled,
  setNumUnreadMsg,
  updateSidebar,
  updateSearchbar,
  resetUiState,
} = uiSlice.actions;

export const uiReducer = uiSlice.reducer;
