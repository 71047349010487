import { useCallback } from "react";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import {
  ElsScanFileUploadTaskContext,
  MultiUploadedFileResponse,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { useAddScansToRevisionAndMergeToMain } from "@hooks/data-management/use-add-scans-to-revision-and-merge-to-main";
import { useHandleAllUploadsCanceled } from "@hooks/data-management/use-handle-all-uploads-canceled";
import { useHandleAllUploadsFailed } from "@hooks/data-management/use-handle-all-uploads-failed";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { CoreFileUploader } from "@utils/core-file-uploader";
import { assert } from "@faro-lotv/foundation";

type ReturnFunction = (
  uploadedResponse: MultiUploadedFileResponse,
  context: ElsScanFileUploadTaskContext
) => Promise<void>;

/** Callback after all files were uploaded or canceled. */
export function useOnUploadComplete(project: SdbProject): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const handleAllUploadsFailed = useHandleAllUploadsFailed();
  const handleAllUploadsCanceled = useHandleAllUploadsCanceled();
  const addScansToRevisionAndMergeToMain = useAddScansToRevisionAndMergeToMain(project);
  const projectApiClient = useProjectApiClient({ projectId: project.id });

  return useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      // The previous code used context.projectId for creating the ProjectAPI client.
      assert(context.projectId === project.id, "Project ID mismatch");

      const successfulUploads = uploadedResponse.successful;
      const failedUploads = uploadedResponse.failed;
      const canceledUploads = uploadedResponse.canceled;
      const uploadSizeMB = uploadedResponse.uploadSizeMB;
      const uploadTimeSecs = uploadedResponse.uploadTimeSecs;
      const uploadSpeedMBps = uploadedResponse.uploadSpeedMBps;
      const uploadSpeedBucketMBps = uploadedResponse.uploadSpeedBucketMBps;

      trackEvent({
        name: DataManagementEvents.finishUpload,
        props: {
          successfulUploads: successfulUploads.length,
          failedUploads: failedUploads.length,
          canceledUploads: canceledUploads.length,
          uploadSizeMB,
          uploadTimeSecs,
          uploadSpeedMBps,
          uploadSpeedBucketMBps,
          // When finishing the upload, the Promise is already resolved.
          uploadEndpoint: await CoreFileUploader.getUploadEndpoint().catch(() => ""),
        },
      });

      // Handle case when there are zero successful uploads
      if (!successfulUploads.length) {
        // If there are failed uploads consider it an all-failed case
        if (failedUploads.length) {
          return handleAllUploadsFailed(
            projectApiClient,
            context.registrationRevisionId
          );
        }

        // If there are zero failed uploads and some canceled uploads consider it an all-canceled case
        if (!failedUploads.length && canceledUploads.length) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId
          );
        }
      }

      // Handle case when there were successful uploads.
      // The function will also check again if the revision was already canceled.
      if (successfulUploads.length) {
        const canceledImport = !(await addScansToRevisionAndMergeToMain(
          successfulUploads,
          failedUploads,
          projectApiClient,
          context
        ));
        if (canceledImport) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId,
            true
          );
        }
      }
    },
    [
      addScansToRevisionAndMergeToMain,
      handleAllUploadsCanceled,
      handleAllUploadsFailed,
      project.id,
      projectApiClient,
      trackEvent,
    ]
  );
}
