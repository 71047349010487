import DateAdapter from "@date-io/luxon";
import { useLocalize } from "@faro-lotv/foreign-observers";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

const ONE_DAY_IN_SECONDS = 86400;

/** The format options for the date */
export type FormatDate = (
  date: string | number | Date,
  formatOptions?: Intl.DateTimeFormatOptions
) => string;

interface UseDateTime {
  /** The function to format and translate dates */
  formatDate: FormatDate;
}

interface FormatAndTranslateDate {
  /** The date to format and translate */
  date: string | number | Date;

  /** The language code to use for the translation */
  languageCode: string;

  /** The format options */
  formatOptions?: Intl.DateTimeFormatOptions;
}

/**
 * Hook to format and translate dates
 *
 * @returns The formatDate function that can be used to format and translate dates
 */
export function useDateTime(): UseDateTime {
  const { currentLanguage } = useLocalize();

  const luxonAdapter = useMemo(
    () => new DateAdapter({ locale: currentLanguage.code }),
    [currentLanguage.code]
  );

  const formatAndTranslateDate = useCallback(
    ({
      date,
      languageCode,
      formatOptions = DateTime.DATE_FULL,
    }: FormatAndTranslateDate): string => {
      const luxonDate = luxonAdapter.date(new Date(date));
      const unixSecondsAbs = Math.abs(luxonDate?.toUnixInteger() ?? 0);

      // We cannot easily compare using luxonDate.{ year, month, day }, since they're already in the user's timezone.
      // So we check instead if it's more than 1 day before or after 1970-01-01.
      return luxonDate && unixSecondsAbs > ONE_DAY_IN_SECONDS
        ? luxonDate.setLocale(languageCode).toLocaleString(formatOptions)
        : "";
    },
    [luxonAdapter]
  );

  const formatDate: UseDateTime["formatDate"] = useCallback(
    (date, formatOptions) =>
      formatAndTranslateDate({
        date,
        languageCode: currentLanguage.code,
        formatOptions,
      }),
    [formatAndTranslateDate, currentLanguage.code]
  );

  return { formatDate };
}
