import { TypedEvent, assert, exponentialBackOff, retry } from "@faro-lotv/foundation";
import {
  BackgroundTaskState,
  ChunkUploadRequestDescription,
  FinalizerCallback,
  UploadFailedError,
} from "@faro-lotv/service-wires";
import { ApiClient } from "@stellar/api-logic";
import { ProjectId } from "@stellar/api-logic/dist/api/core-api/api-types";
import { IChunkUploadResponse } from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { checkMagicFileHeader } from "@utils/file-utils";
import pLimit from "p-limit";
import { ChunkedMD5 } from "@utils/chunked-md5";
import { delay } from "@utils/time-utils";
import { getCachedTokenProvider } from "@api/use-cached-token-provider";
import { runtimeConfig } from "@src/runtime-config";

// Sets the maximum no. of chunks uploaded simultaneously within each file.
// Works together with MAX_CONCURRENT_UPLOADS.
// Initial value only; will be auto-adjusted by UploadManager.setMaxConcurrentUploadsAuto().
export const MAX_CONCURRENT_CHUNKS = 2;

const SMALLEST_ERROR_CODE = 300;

// For the first i = [0 ... MAX_CONCURRENT_CHUNKS - 1] chunks, we wait (i * CHUNK_UPLOAD_DELAY) milliseconds.
// This is intended to better utilize the available bandwidth, since then not all chunks are waiting for the
// OPTIONS request at the same time.
const CHUNK_UPLOAD_DELAY = 200;

/** Upload endpoint: Auto (prefer direct storage upload if available), Frontdoor or Storage. */
export type UploadEndpoint = "auto" | "frontdoor" | "storage";

/**
 * A class to upload a file to a given holobuilder project through the Core API.
 * Useful to e.g. upload a point cloud to a project.
 *
 * Possible future developments of this class:
 * * Add support for retrying uploads of single chunks when they fail for timeout
 * * Add support for retrying the whole upload
 * * Improve error handling since first two API calls may also fail
 * * Add support for interrupting and resuming deliberately an upload.
 */
export class CoreFileUploader {
  static #maxConcurrentChunks = MAX_CONCURRENT_CHUNKS;
  static #uploadEndpoint: UploadEndpoint = "auto";
  static #uploadEndpointPromise: Promise<UploadEndpoint> | null = null;

  #file: File;
  #projectId: ProjectId;
  #coreApi: ApiClient;
  #bytesUploaded = 0;
  // Set to performance.now() before first chunk is uploaded.
  #timeStartChunks = 0;
  #progress = 0;
  #state: BackgroundTaskState = BackgroundTaskState.created;
  #chunkLimiter = pLimit(CoreFileUploader.#maxConcurrentChunks);
  #chunkAbortController: AbortController = new AbortController();

  /** Event emitted when the upload progress advanced. Argument is the current progress from 0 to 100. */
  progressChanged = new TypedEvent<{
    percentage: number;
    expectedEnd: number;
    speedMBps: number;
  }>();

  /**
   * Event emitted when the upload completed.
   * The argument is the URL at which the file can be downloaded and the md5 hash of the file.
   */
  uploadCompleted = new TypedEvent<{ downloadUrl: string; md5: string }>();

  /** Event emitted when the upload fails. The argument conveys available information about the upload error. */
  uploadFailed = new TypedEvent<Error>();

  /** Event emitter when the upload is canceled */
  uploadCanceled = new TypedEvent<undefined>();

  /**
   *
   * @param file The file to upload
   * @param projectId The ID of the project to upload the file to
   * @param coreApi The Core API client to be used for uploading.
   */
  constructor(file: File, projectId: ProjectId, coreApi: ApiClient) {
    this.#file = file;
    this.#projectId = projectId;
    this.#coreApi = coreApi;
  }

  /**
   * Set the maximum number of chunks that can be uploaded concurrently, both for future uploads,
   * and for the given existing instances.
   * @param maxChunks Integer >= 1.
   * @param instances Optional list of existing CoreFileUploader instances.
   */
  static setMaxConcurrentChunks(maxChunks: number, instances?: CoreFileUploader[]): void {
    assert(Number.isInteger(maxChunks) && maxChunks >= 1);
    // Adapt limit for future uploads:
    CoreFileUploader.#maxConcurrentChunks = maxChunks;
    // Adapt limit for current uploads:
    for (const instance of instances ?? []) {
      instance.#chunkLimiter.concurrency = maxChunks;
    }
  }

  /** Set the upload endpoint to use. */
  static setUploadEndpoint(endpoint: UploadEndpoint): void {
    assert(endpoint === "auto" || endpoint === "frontdoor" || endpoint === "storage");

    CoreFileUploader.#uploadEndpoint = endpoint;
    if (endpoint === "auto") {
      // Make sure that #determineUploadEndpoint() is called again.
      CoreFileUploader.#uploadEndpointPromise = null;
    } else {
      // For completeness, set the Promise to the same value.
      CoreFileUploader.#uploadEndpointPromise = Promise.resolve(endpoint);
    }
  }

  /**
   * After max. 12 seconds in total, determines if direct upload to Azure Blob Storage is possible.
   * For some customers, the requests to "*.windows.net" might be blocked by their DNS-based firewall.
   * Frontdoor uses a "*.holobuilder.(com|eu)" URL, which makes success more likely, and it's also the URL
   * that was used all the time, so customers are likely to have it whitelisted.
   *
   * Our assumption is that requests to Azure Storage:
   * - go through fine (with timeout and max. 1 retry), or
   * - are blocked by the firewall on the DNS lookup, or
   * - are redirected by the firewall on the DNS lookup (which may result in an HTTPS error), or
   * - are blocked when trying to connect to the server.
   *
   * @returns "storage" for direct upload, or "frontdoor" for upload via Frontdoor.
   *          The returned Promise always resolves; all errors are caught.
   */
  static async #determineUploadEndpoint(): Promise<UploadEndpoint> {
    const MAX_TRIES = 2;
    const RETRY_DELAY_INITIAL = 2000;
    const TIMEOUT = 5000;

    for (let i = 0; i < MAX_TRIES; i++) {
      try {
        const signal = AbortSignal.timeout(TIMEOUT);
        const res = await fetch(`${runtimeConfig.urls.uploadStorageUrl}/`, {
          // We make a PUT request since PUT is also used for the chunk uploads.
          method: "PUT",
          signal,
        });
        const resText = await res.text();
        // We expect a 400 error with XML body.
        // By checking for several expected strings, we try to make the check more robust.
        // Using "<Error" so that it matches both "<Error>" and "<Error someAttr="...">".
        const hasExpectedBody = resText.includes("InvalidQueryParameterValue") ||
          (resText.includes("<Error") && resText.includes("<Code"));
        // Search for e.g. "x-ms-request-id". That header is exposed through CORS and accessible.
        const hasExpectedHeader =  [...res.headers.keys()]
          .some((headerName) => headerName.toLowerCase().startsWith("x-ms-"));
        if (hasExpectedBody || hasExpectedHeader) {
          // The response that we received is most likely from Azure Blob Storage.
          // -> We assume that uploading directly to the storage is possible.
          return "storage";
        }
      } catch (error) {
        // NOP - try again or give up.
      }
      if (i < MAX_TRIES - 1) {
        await delay((i + 1) * RETRY_DELAY_INITIAL);
      }
    }
    return "frontdoor";
  }

  /**
   * Calls #determineUploadEndpoint() only once, and then caches the result.
   * It also caches the Promise, to avoid that two concurrent checks are performed.
   *
   * @returns "storage" for direct upload, or "frontdoor" for upload via Frontdoor.
   *          The returned Promise always resolves; all errors are caught.
   */
  static async getUploadEndpoint(): Promise<UploadEndpoint> {
    if (CoreFileUploader.#uploadEndpoint !== "auto") {
      return CoreFileUploader.#uploadEndpoint;
    } else if (CoreFileUploader.#uploadEndpointPromise) {
      return CoreFileUploader.#uploadEndpointPromise;
    }

    CoreFileUploader.#uploadEndpointPromise = CoreFileUploader.#determineUploadEndpoint()
      .then((endpoint) => CoreFileUploader.#uploadEndpoint = endpoint);
    return await CoreFileUploader.#uploadEndpointPromise;
  }

  /**
   *
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @returns Whether the upload has been canceled by the user.
   */
  #checkCanceled(signal?: AbortSignal): boolean {
    const { aborted, failed, succeeded } = BackgroundTaskState;
    if (signal?.aborted && this.#state !== aborted && this.#state !== failed && this.#state !== succeeded) {
      this.#state = BackgroundTaskState.aborted;
      this.uploadCanceled.emit(undefined);
    }
    return this.#state === BackgroundTaskState.aborted;
  }

  /**
   * Upload a single chunk to the backend
   *
   * @param chunk to upload
   * @param spark buffer to compute entire file MD5
   * @param signal Combined signal for (user abort || one of the chunks failed)
   * @returns True if the chunk was uploaded successfully. False if the upload was aborted.
   * @throws {Error} If the upload failed.
   */
  private async uploadChunk(
    chunk: ChunkUploadRequestDescription,
    spark: ChunkedMD5,
    signal: AbortSignal
  ): Promise<boolean> {
    // upload chunk
    const startByte = chunk.bytes.start;
    const endByte = chunk.bytes.start + chunk.bytes.length;
    const blob = this.#file.slice(startByte, endByte);
    const blobData = await blob.arrayBuffer();
    spark.append(blobData, startByte);
    // check cancellation for early return
    if (this.#checkCanceled(signal)) {
      return false;
    }

    const chunkUrl = CoreFileUploader.#uploadEndpoint === "storage" ?
      chunk.url.replace(runtimeConfig.urls.uploadFrontDoorUrl, runtimeConfig.urls.uploadStorageUrl) :
      chunk.url;

    const response = await retry(
      () =>
        fetch(chunkUrl, {
          method: chunk.method,
          body: blobData,
          headers: {
            ...chunk.headers,
          },
          signal,
        }),
      {
        max: 5,
        delay: exponentialBackOff,
      }
    );
    // check cancellation for early return
    if (this.#checkCanceled(signal)) {
      return false;
    }
    // handle error
    if (!response.ok || response.status >= SMALLEST_ERROR_CODE) {
      throw new UploadFailedError(
        response.status,
        response.statusText,
        startByte,
        this.#file.size
      );
    }
    // progress 1 - update members
    this.#bytesUploaded += chunk.bytes.length;
    this.#progress = Math.floor((this.#bytesUploaded * 100) / this.#file.size);
    // progress 2 - emit event
    this.#reportProgress();

    return true;
  }

  /** Emit progress event, e.g. for the "cloud menu" and Staging Area progress. */
  #reportProgress(): void {
    const msecElapsed = performance.now() - this.#timeStartChunks;
    const bytes = this.#bytesUploaded;
    if (msecElapsed <= 0 || bytes <= 0) {
      return;
    }

    const speed = bytes / msecElapsed;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- (1024 * 1024) = bytes in MBytes.
    const speedMBps = bytes / (1024 * 1024) / (msecElapsed / 1000);
    const remainingTime = (this.#file.size - bytes) / speed;
    const expectedEnd = Date.now() + remainingTime;

    this.progressChanged.emit({ percentage: this.#progress, expectedEnd, speedMBps });
  }

  /**
   * Finalize the upload to the backend
   *
   * @param chunkUploadDescription the descriptor for the entire upload
   * @param spark buffer to compute entire file MD5
   * @param signal to abort the upload
   * @param finalizer function to commit this upload to the project
   */
  private async finalizeUpload(
    chunkUploadDescription: IChunkUploadResponse,
    spark: ChunkedMD5,
    signal?: AbortSignal,
    finalizer?: FinalizerCallback
  ): Promise<void> {
    // Finalize
    const { finalize } = chunkUploadDescription;
    const finalizeUrl = CoreFileUploader.#uploadEndpoint === "storage" ?
      finalize.url.replace(runtimeConfig.urls.uploadFrontDoorUrl, runtimeConfig.urls.uploadStorageUrl) :
      finalize.url;

    const ret = await fetch(finalizeUrl, {
      method: finalize.method,
      headers: { ...finalize.headers },
      body: finalize.body,
      signal,
    });

    // check cancellation
    if (this.#checkCanceled(signal)) {
      return;
    }
    // check error
    if (!ret.ok || ret.status >= SMALLEST_ERROR_CODE) {
      throw new UploadFailedError(
        ret.status,
        ret.statusText,
        this.#file.size,
        this.#file.size
      );
    }

    const { downloadUrl } = chunkUploadDescription;
    const md5 = spark.end();
    if (finalizer) {
      await finalizer(downloadUrl, md5);
    }

    // Finished!
    this.#state = BackgroundTaskState.succeeded;
    this.uploadCompleted.emit({
      downloadUrl,
      md5,
    });
  }

  /**
   * Performs the uploading. This function does not throw any exceptions,
   * all exceptions are caught internally and sent via the 'uploadFailed' signal.
   *
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @param finalizer An optional function to call to finalize an upload, if it fails the upload is considered failed
   */
  async doUpload(
    signal?: AbortSignal,
    finalizer?: FinalizerCallback
  ): Promise<void> {
    if (
      this.#state !== BackgroundTaskState.created &&
      this.#state !== BackgroundTaskState.scheduled
    ) {
      return;
    }

    this.#state = BackgroundTaskState.started;

    try {
      // A failed check here is currently not logged to Sentry.
      await checkMagicFileHeader(this.#file);

      // Trigger detection of upload endpoint, if not already started.
      // We should wait for the result, since making the generateChunkUploadData() requests in parallel
      // can lead to a timeout here, which would cause a fallback to Frontdoor.
      await CoreFileUploader.getUploadEndpoint().catch(() => undefined);

      // Get token for project
      const tokenProvider = getCachedTokenProvider(this.#projectId);
      const token = await tokenProvider();

      // check cancellation
      if (this.#checkCanceled(signal)) {
        return;
      }
      // Get description on how to split the chunked upload.
      const chunkUploadDescription =
        await this.#coreApi.V3.SDB.generateChunkUploadData({
          projectId: this.#projectId,
          // without the mimetype below, the whole upload does not work.
          contentType: "application/octet-stream",
          downloadName: this.#file.name,
          size: this.#file.size,
          token,
        });
      // check cancellation
      if (this.#checkCanceled(signal)) {
        return;
      }

      // Combined signal for (upload aborted by user || one of the chunks failed).
      const combinedSignal = signal ? AbortSignal.any([
        signal,
        this.#chunkAbortController.signal,
      ]) : this.#chunkAbortController.signal;

      // initialize MD5 computation
      const spark = new ChunkedMD5();

      // Reference time for progress reports.
      this.#timeStartChunks = performance.now();

      // progressively upload all file chunks
      const chunkUploadPromises: Promise<boolean>[] =
        chunkUploadDescription.chunks.map((chunk: ChunkUploadRequestDescription, idx: number) => {
          return this.#chunkLimiter(async (): Promise<boolean> => {
            // See comment for CHUNK_UPLOAD_DELAY.
            if (idx > 0 && idx < CoreFileUploader.#maxConcurrentChunks) {
              await delay(idx * CHUNK_UPLOAD_DELAY);
            }
            return this.uploadChunk(chunk, spark, combinedSignal);
          });
        });

      // throws Error if at least one of the chunk uploads failed.
      await Promise.all(chunkUploadPromises);

      await this.finalizeUpload(
        chunkUploadDescription,
        spark,
        combinedSignal,
        finalizer
      );
    } catch (err) {
      // clear the chunkLimiter queue
      if (this.#chunkLimiter.pendingCount) {
        this.#chunkLimiter.clearQueue();
      }
      // abort the running chunk uploads
      this.#chunkAbortController.abort();

      if (this.#checkCanceled(signal)) {
        return;
      }
      this.#state = BackgroundTaskState.failed;
      if (err instanceof Error) {
        this.uploadFailed.emit(err);
      } else {
        this.uploadFailed.emit(
          new Error("Upload failed because of an unknown error.")
        );
      }
    }
  }

  /** @returns The upload progress from 0 to 100. */
  get progress(): number {
    return this.#progress;
  }

  /** @returns the file upload state */
  get state(): BackgroundTaskState {
    return this.#state;
  }

  /** @returns the file being uploaded */
  get file(): File {
    return this.#file;
  }
}
