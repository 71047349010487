import { Box, CircularProgress, Typography, Divider, Checkbox } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { MarkupsSidePanelAttachmentItem } from "@pages/project-details/project-markups/sidepanel";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { MarkupUploadAttachment } from "@pages/project-details/project-markups/sidepanel/attachment/markup-upload-attachment";
import { useState, useMemo } from "react";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { nounPluralize } from "@utils/data-display";
import { MarkupUploadingAttachment } from "@pages/project-details/project-markups/sidepanel/attachment/markup-uploading-attachment";
import { CommonStyles } from "@styles/common-styles";
import { BulkDeleteMarkupAttachments } 
from "@pages/project-details/project-markups/sidepanel/attachment/attachment-bulk-actions/bulk-delete-markup-attachments";
import { FaroTextButton } from "@components/common/faro-text-button";

/**
 * Component responsible for displaying and managing attachments associated with a markup in the side panel.
 *
 * @param {BaseMarkupProps} props - The properties for the component, including the markup data.
 * @returns {JSX.Element} The rendered component.
 */
export function MarkupsSidePanelAttachments({
  markup,
}: BaseMarkupProps): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const { hasPermissionToEditMarkup , projectId } = useMarkupContext();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const numberOfAttachments = markup?.attachments.length || 0;

  const [selectedAttachments, setSelectedAttachments] = useState<Set<string>>(
    () => new Set()
  );
  const numberOfSelected = selectedAttachments.size;

  /**
   * Downloads images associated with a markup by creating individual download links for each image.
   * The images are downloaded sequentially using fetch and Blob API.
   * Depending on the user's selection, it either downloads all attachments or only the selected ones.
   *
   * @returns A Promise that resolves once all images are successfully downloaded.
   */
  async function downloadAttachments(): Promise<void> {
    if (!numberOfAttachments) {
      return;
    }

    // TODO: https://faro01.atlassian.net/browse/ST-1757 Create a zip file for the multiple images
    try {
      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: {
          property: isSomeSelected ? "download selected annotation attachments" : "download all annotation attachments",
          isValueEmpty: false,
        },
      });
      
      const attachmentsToDownload = isSomeSelected
      ? selectedAttachmentObjects
      : markup?.attachments || [];

      if (attachmentsToDownload.length === 0) {return;}
      setIsDownloading(true);
      await Promise.all(
        attachmentsToDownload.map(async (attachment) => {
          const response = await fetch(attachment.uri);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = attachment.name;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          window.URL.revokeObjectURL(url);
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: "error when we were downloading the attachments",
        title: "Error with the download",
        error,
      });
    } finally {
      setIsDownloading(false);
    }
  }

  /** Handles the change event when the select all checkbox is clicked */
  function handleSelectAllChange(): void {
    setSelectedAttachments(() => {
      if (isAllSelected || isSomeSelected) {
        return new Set();
      } else {
        return new Set(markup.attachments.map((att) => att.id));
      }
    });
  }

  /** Handles the change event when a checkbox for an attachment is clicked */
  function handleAttachmentCheckboxChange(
    attachmentId: string,
    isChecked: boolean
  ): void {
    setSelectedAttachments((prev) => {
      const newSet = new Set(prev);
      if (isChecked) {
        newSet.add(attachmentId);
      } else {
        newSet.delete(attachmentId);
      }
      return newSet;
    });
  }

  const isAllSelected = numberOfSelected === numberOfAttachments && numberOfAttachments > 0;
  const isSomeSelected = numberOfSelected > 0 && numberOfSelected < numberOfAttachments;

  const selectedAttachmentObjects = useMemo(() => {
    return markup.attachments.filter((attachment) =>
      selectedAttachments.has(attachment.id)
    );
  }, [markup.attachments, selectedAttachments]);

  return (
    <Box 
      sx={{
      position: "relative",
      overflowY: "auto",
      maxHeight: "100%",
      mt: "30px",
      display: "flex",
      flexDirection: "column",
      paddingRight: "15px",
      }}
    >
      {hasPermissionToEditMarkup && <MarkupUploadAttachment markup={markup} />}

      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: sphereColors.pureWhite,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {numberOfAttachments ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "5px",
              paddingBottom: 0,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={isAllSelected}
                indeterminate={isSomeSelected}
                onChange={handleSelectAllChange}
                sx={{ mr: 1 }}
              />
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.gray800,
                fontSize: "14px",
              }}
            >
              {nounPluralize({
                counter: numberOfAttachments,
                word: "Attachment",
              })}
            </Typography>
            </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {isDownloading ? (
              <CircularProgress size={"1rem"} sx={{ ml: "5px" }} />
            ) : (
              <FaroTextButton
                // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error  
                onClick={downloadAttachments}
                sx={{
                  fontWeight: 600,
                  paddingY: "6px",
                  width: "80px",
                  color: sphereColors.blue500,
                }}
              >
                Download
              </FaroTextButton>
            )}
            { hasPermissionToEditMarkup && (isAllSelected || isSomeSelected) && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: CommonStyles.Borders.gray200Divider,
                  }}
                />
                <BulkDeleteMarkupAttachments 
                  selectedAttachments={selectedAttachmentObjects}
                  projectId={projectId} 
                  selectedMarkup={markup}
                  setSelectedAttachments={setSelectedAttachments}
                />
             </>
            )}
          </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              fontWeight: 600,
              color: sphereColors.gray800,
              mb: "10px",
              fontSize: "14px",
            }}
          >
            No attachments
          </Typography>
        )}
      </Box>
      <Box>
        <MarkupUploadingAttachment markupId={markup.id} />
        {markup?.attachments && ( markup.attachments.map((attachment) => (
            <MarkupsSidePanelAttachmentItem
              attachment={attachment}
              key={attachment.id}
              isChecked={selectedAttachments.has(attachment.id)}
              onCheckboxChange={handleAttachmentCheckboxChange}
              setSelectedAttachments={setSelectedAttachments}
            />
          ))
        )}
      </Box>
    </Box>
  );
}
