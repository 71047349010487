import { Box, useMediaQuery, useTheme } from "@mui/material";
// Use Grid version 2
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import MenuIcon from "@assets/icons/generic-menu_l.svg?react";
import { updateSidebar } from "@store/ui/ui-slice";
import { Outlet } from "react-router-dom";
import { Searchbar } from "@components/header/searchbar";
import { UserMenu } from "@components/header/user-menu";
import { FaroBreadcrumbs } from "@components/header/faro-breadcrumbs";
import { ProjectOverviewButton } from "@components/header/project-overview-button";
import { useShouldShowSearchbar } from "@hooks/route-match/use-should-show-searchbar";
import { HEADER_HEIGHT } from "@utils/ui-utils";
import {
  CONTENT_MAX_WIDTH,
  PAGE_X_MARGINS,
  withEllipsis,
} from "@styles/common-styles";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import { ResendVerification } from "@components/resend-verification";
import { sidebarSelector } from "@store/ui/ui-selector";
import { ProgressOverviewMenu } from "@components/progress-overview/progress-overview-menu";
import { FaroHelpCenterMenu } from "@components/header/faro-help-center-menu";
import { TrialExpirationBanner } from "@components/banners/trial-expiration-banner";
import { WORKSPACE_SPECIFIC_BANNERS } from "@components/banners/workspace-specific-banner/workspace-specific-banner-constants";
import { WorkspaceSpecificBanner } from "@components/banners/workspace-specific-banner/workspace-specific-banner";

interface Props extends PropsWithChildren {
  /** Flag whether the Sphere XG logo should be shown on the left side of the screen */
  shouldShowLogo?: boolean;

  /** Flag whether the open button should be hidden regardless of the screen size*/
  shouldHideOpenButton?: boolean;

  /** Flag whether the user menu should be shown. Default true */
  shouldShowUserMenu?: boolean;

  /** Flag whether the sidebar should be shown. Default true */
  shouldShowSidebar?: boolean;

  /** Flag whether the progress overview menu should be shown. Default true */
  shouldShowProgressOverviewMenu?: boolean;

  /** Flag whether the Staging Area is shown. Default false */
  isShowingStagingArea?: boolean;

  /** Optional page title to display in the center */
  titleComponent?: ReactNode;

  /** The color of topbar background if need to use different one */
  topbarBackgroundColor?: string;

  /** Indicates if the topbar should have a bottom border */
  shouldShowTopbarBorder?: boolean;

  /** Color of the content background */
  contentBackgroundColor?: CSSProperties["color"];

  /** Logics related to close button */
  closeButton?: {
    /** Whether to show the close button in left or right of screen */
    alignment: "left" | "right";

    /** Callback function that triggers when the close button is closed */
    onClick?(): void;

    /** Whether to show close text or not */
    shouldShowText?: boolean;
  };
}

/**
 * Describes the layout for the Sphere Dashboard website.
 * Includes the header bar, the breadcrumbs and the "Outlet", in which the
 * router will inject every page content.
 *
 * To keep margins consistent across pages, every page should not have neither padding
 * nor margins for the top component, instead we define those margins and paddings here.
 *
 * @returns React main page layout component.
 */
export function MainPageLayout({
  children,
  shouldHideOpenButton = false,
  shouldShowLogo = false,
  shouldShowUserMenu = true,
  shouldShowSidebar = true,
  shouldShowProgressOverviewMenu = true,
  isShowingStagingArea = false,
  closeButton,
  titleComponent,
  topbarBackgroundColor = sphereColors.pureWhite,
  shouldShowTopbarBorder,
  contentBackgroundColor,
}: Props): JSX.Element {
  const shouldShowSearchbar = useShouldShowSearchbar();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isScreenSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));
  const sidebar = useAppSelector(sidebarSelector);

  /** The content for the close button */
  const closeButtonContent = useMemo(() => {
    if (closeButton) {
      return (
        <Box component="div">
          <FaroIconButton component={CloseIcon} onClick={closeButton.onClick} />
          {closeButton.shouldShowText ? "Close" : ""}
        </Box>
      );
    }
  }, [closeButton]);

  useEffect(() => {
    dispatch(updateSidebar({ ...sidebar, isVisible: shouldShowSidebar }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Putting sidebar on dependency will create a loop
  }, [dispatch, shouldShowSidebar]);

  return (
    <Grid sx={{ width: "100%" }}>
      {/* Banners that show for specific workspaces */}
      {WORKSPACE_SPECIFIC_BANNERS.map((banner, index) => (
        <WorkspaceSpecificBanner key={index} {...banner} />
      ))}

      {/* Banner to remind users to verify email address */}
      <ResendVerification />

      {/* Trial expiration banner */}
      <TrialExpirationBanner />

      {/* Contains the header bar */}
      <Grid
        container
        sx={{
          // The logo needs a smaller padding on the left side
          pl: shouldShowLogo ? "8px" : PAGE_X_MARGINS,
          pr: PAGE_X_MARGINS,
          py: isShowingStagingArea ? "0px" : "16px",
          backgroundColor: topbarBackgroundColor,
          borderBottom: shouldShowTopbarBorder
            ? `1px solid ${sphereColors.gray200}`
            : "",
          justifyContent: "space-between",
        }}
        data-testid="HEADER_CONTAINER"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={isShowingStagingArea ? "nowrap" : "wrap"}
        width="100%"
        height={HEADER_HEIGHT}
      >
        {/* Left side of the top-bar */}
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          {/* Large button in the Staging Area to go back to the project overview */}
          {isShowingStagingArea && <ProjectOverviewButton />}

          {shouldShowLogo && (
            <Box component="div">
              <FaroSphereLogo />
            </Box>
          )}

          {closeButton?.alignment === "left" && closeButtonContent}

          {/* Button to open drawer */}
          {!isScreenSmallAndLarger && !shouldHideOpenButton && (
            <Grid alignItems="center">
              <Box
                component="div"
                sx={{
                  color: sphereColors.gray800,
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "28px",
                  letterSpacing: "0.16px",
                }}
              >
                <FaroIconButton
                  aria-label="open drawer"
                  iconButtonProps={{
                    edge: "start",
                    sx: {
                      mr: 2,
                      display: { sm: "none" },
                    },
                  }}
                  component={MenuIcon}
                  iconSize="20px"
                  onClick={() => {
                    dispatch(updateSidebar({ isOpenOnMobile: true }));
                  }}
                />
              </Box>
            </Grid>
          )}

          {isScreenSmallAndLarger && (
            <Grid alignItems="center">
              <FaroBreadcrumbs />
            </Grid>
          )}
        </Box>

        {/* Center of the top-bar */}
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            ...withEllipsis,
          }}
        >
          {titleComponent && (
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
                ...withEllipsis,
              }}
            >
              {titleComponent}
            </Box>
          )}
        </Box>

        {/* Right side of the top-bar */}
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexBasis: 0,
            justifyContent: "right",
          }}
        >
          {/* Search bar */}
          {shouldShowSearchbar && (
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component="div"
                sx={{
                  minWidth: "100px",
                  width: "200px",
                }}
              >
                <Searchbar />
              </Box>
            </Box>
          )}
          {/* Icons and buttons */}
          <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
            <FaroHelpCenterMenu />

            {shouldShowProgressOverviewMenu && (
              <Box component="div" sx={{ marginLeft: "12px" }}>
                <ProgressOverviewMenu />
              </Box>
            )}

            {shouldShowUserMenu && (
              <Box component="div" sx={{ marginLeft: "12px" }}>
                <UserMenu />
              </Box>
            )}

            {closeButton?.alignment === "right" && closeButtonContent}
          </Box>
        </Box>
      </Grid>
      {/* Contains the rest of the content */}
      <Grid
        data-testid="CONTENT_WRAPPER"
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          px: {
            xs: PAGE_X_MARGINS.xs,
            sm: PAGE_X_MARGINS.sm,
            md: PAGE_X_MARGINS.md,
            lg: PAGE_X_MARGINS.lg,
            xl: PAGE_X_MARGINS.xl,
          },
          paddingBottom: "30px",
          backgroundColor: contentBackgroundColor,
        }}
      >
        <Grid
          data-testid="CONTENT"
          sx={{
            width: "100%",
            maxWidth: {
              xs: CONTENT_MAX_WIDTH.xs,
              sm: CONTENT_MAX_WIDTH.sm,
              md: CONTENT_MAX_WIDTH.md,
              lg: CONTENT_MAX_WIDTH.lg,
              xl: CONTENT_MAX_WIDTH.xl,
            },
          }}
        >
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          {children ? children : <Outlet />}
        </Grid>
      </Grid>
    </Grid>
  );
}
