import { useCoreApiClient } from "@api/use-core-api-client";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { GroupDetailsInfoBar } from "@components/group-details/group-details-info-bar";
import { ProjectsTable } from "@components/table/projects/projects-table";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { ProjectArchivingState } from "@custom-types/project-types";
import { useAppParams } from "@router/router-helper";
import {
  activeProjectsOfSelectedGroupSelector,
  fetchingProjectsFlagsSelector,
  nextProjectSelector,
} from "@store/projects/projects-selector";
import { fetchProjects } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect, useMemo } from "react";
import ProjectsDuoColorIcon from "@assets/icons/projects-duo-color.svg?react";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectsPageBulkActions } from "@pages/projects/projects-page-bulk-actions";
import { LoadMoreButton } from "@components/common/button/load-more-button";

interface Props extends Partial<BaseGroupDetailsProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Projects tab of the group details
 */
export function GroupProjects({
  group,
  isLoading = false,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();

  const activeProjectsOfSelectedGroup = useAppSelector(
    activeProjectsOfSelectedGroupSelector
  );
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);

  /** Stores the id of the next project to be fetched */
  const nextProject = useAppSelector(nextProjectSelector);

  /**
   * Flag whether the load more projects button should be hidden, either because there are no more projects to fetch,
   * or because the user is searching for projects.
   */
  const shouldHideLoadMoreButton = useMemo(() => {
    return !nextProject || isFetchingProjects;
  }, [nextProject, isFetchingProjects]);

  // Fetches group projects as the available project details for the group at this stage is not enough to display table
  // This is because each time group object will be recognized as different object
  useEffect(() => {
    if (!group?.id) {
      // Do not fetch if group is not yet available because we would
      // not know the groupId and would fetch all projects instead
      return;
    }
    if (companyId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          groupId: group.id,
          projectArchivingState: ProjectArchivingState.active,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, group?.id]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  let emptyPageContent = null;
  if (
    activeProjectsOfSelectedGroup.length === 0 &&
    !isFetchingProjects &&
    !isLoading
  ) {
    emptyPageContent = (
      <EmptyPage
        title="This group has no active projects"
        // eslint-disable-next-line max-len -- needed per design
        subtitle="You don't have any active projects connected to this group. Create a new project or add an existing active project to this group."
        icon={ProjectsDuoColorIcon}
      />
    );
  }

  const buttonComponents = ProjectsPageBulkActions({
    companyId,
    projects: activeProjectsOfSelectedGroup,
    projectStatus: ProjectArchivingState.active,
    subjectType: "group",
  });

  /**
   * Calls the backend to fetch more projects and increases the number of projects shown on the screen.
   */
  async function loadMoreProjects(): Promise<void> {
    if (companyId && nextProject) {
      if (!group?.id) {
        // Do not fetch if group is not yet available because we would
        // not know the groupId and would fetch all projects instead
        return;
      }

      await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          groupId: group.id,
          projectArchivingState: ProjectArchivingState.active,
          next: nextProject,
        })
      );
    }
  }

  return (
    <>
      <GroupDetailsInfoBar group={group} />
      {emptyPageContent ?? (
        <>
          <ProjectsTable
            companyId={companyId}
            projects={activeProjectsOfSelectedGroup}
            isLoading={isFetchingProjects || isLoading}
            contextType={"group projects"}
            requiredColumns={[
              ProjectHeaders.name,
              ProjectHeaders.managerAvatar,
              ProjectHeaders.projectManager,
              ProjectHeaders.lastUpdated,
              ProjectHeaders.accessLevel,
              ProjectHeaders.options,
            ]}
            bulkActionButtons={buttonComponents}
          />

          <LoadMoreButton
            isLoading={isLoading}
            buttonText="Load more projects"
            shouldHideLoadMoreButton={shouldHideLoadMoreButton}
            onClick={loadMoreProjects}
          />
        </>
      )}
    </>
  );
}
