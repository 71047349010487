import { isAddScanTask } from "@custom-types/sdb-background-tasks-type-guards";
import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { createSelector } from "@reduxjs/toolkit";
import {
  captureTreeEntityClusterPathSelector,
  captureTreeForMainRevisionSelector,
  isCaptureTreeScanEntityProcessingSelector,
  hasCaptureTreeScanEntityTaskErrorsSelector,
  allRegistrationRevisionsSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import { isScanEntity } from "@utils/capture-tree-utils";
import { ProcessElsRawScanTask, ProcessPointCloudFlsRawTask } from "@custom-types/sdb-background-tasks-types";

/**
 * @returns All UploadedData entities.
 */
export const uploadedDataSelector: (state: RootState) => UploadedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = captureTreeForMainRevisionSelector(state);
      const scanEntities = entities.filter(isScanEntity);

      return scanEntities.map((entity) => {
        return {
          ...entity,
          clusterPath: captureTreeEntityClusterPathSelector(entity.id)(state),
          isProcessing: isCaptureTreeScanEntityProcessingSelector(entity.id)(state),
          hasTaskErrors: hasCaptureTreeScanEntityTaskErrorsSelector(entity.id)(state),
        };
      });
    }
  );

/**
 * @returns All tasks that added scans to the project, sorted from newest creation date to oldest.
 */
export const addScanTasksSelector: (state: RootState) => (ProcessElsRawScanTask | ProcessPointCloudFlsRawTask)[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = allRegistrationRevisionsSelector(state);
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const allAddScanTasks = backgroundTasks.filter(isAddScanTask);

      const addScanTasksOfProject = allAddScanTasks.filter((addScanTask) => {
        return revisions.find((revision) => addScanTask.context?.projectId === revision.projectId);
      });

      return addScanTasksOfProject
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/**
 * @returns true if there is any UploadedData entity getting processed.
 */
export const isProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      return entities.some((entity) => entity.isProcessing);
    }
  );

/**
 * @returns true if all UploadedData entities, at least one, have been processed.
 */
export const isProcessedSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      // isProcessing also returns false if processing hasn't started yet. So also check the desired result.
      // There needs to be the initial ElsRaw and the created E57 point cloud.
      return 0 < entities.length && entities.every((entity) =>
        !entity.isProcessing && entity.pointClouds?.length && 2 <= entity.pointClouds.length
      );
    }
  );

/**
 * @returns true if there is any UploadedData entity with an error.
 */
export const hasProcessErrorSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = uploadedDataSelector(state);
      return entities.some((entity) => entity.hasTaskErrors);
    }
  );
