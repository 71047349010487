import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { shouldDisableSendInvite } from "@pages/members/teams/teams-utils";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useReducer } from "react";
import { useAppDispatch } from "@store/store-helper";
import { useToast } from "@hooks/use-toast";
import { convertToTeamMemberTypes } from "@store/members/members-slice-utils";
import { addMembersToTeam, createTeam } from "@store/teams/teams-slice-thunk";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "@api/use-core-api-client";
import { isValidTeamName } from "@utils/team-utils";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice-thunk";
import { setOne } from "@store/members/members-slice";
import { nounPluralize } from "@utils/data-display";
import { CreateTeamDialogContent } from "@pages/members/teams/create-team-dialog-content";
import { reducerTeams } from "@pages/members/teams/create-team-reducer";

interface Props extends BaseCompanyIdProps {
  /** If the dialog it's opened or not  */
  isDialogOpen: boolean;

  /** Close the dialog */
  onCloseDialog: () => void;
}

/** The max number of characters for the input name */
export const MAX_NAME_CHARACTERS = 50;

/** The max number of characters for the input description */
export const MAX_DESCRIPTION_CHARACTERS = 500;

/** The dialog for the create team */
export function CreateTeamDialog({
  companyId,
  isDialogOpen,
  onCloseDialog,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();

  const { trackEvent } = useTrackEvent();
  const { showToast } = useToast();
  const coreApiClient = useCoreApiClient();

  const [state, dispatchTeams] = useReducer(reducerTeams, {
    isLoading: false,
    teamName: "",
    description: "",
    isMemberInputValid: false,
    selectedMembersIds: [],
  });

  /** Determines if the team name is valid */
  const isValidName = isValidTeamName(state.teamName);

  /**
   * Whether the create button should be disabled or not.
   * The button should be disabled if:
   * - The team name is empty
   * - The team name isn't valid
   * - The number of selected members exceeds the maximum allowed for invitation.
   *  Note: Selecting members is optional. However, if members are selected, their count must be valid
   *  (i.e., within the allowed range).
   */
  const isCreateDisabled =
    (state.selectedMembersIds.length > 0 &&
      shouldDisableSendInvite(
        state.selectedMembersIds,
        state.isMemberInputValid
      )) ||
    !isValidName ||
    state.teamName.trim().length < 1 ||
    state.teamName.trim().length > MAX_NAME_CHARACTERS ||
    state.description.trim().length > MAX_DESCRIPTION_CHARACTERS ||
    ((state.selectedRole === CoreAPITypes.EUserCompanyRole.companyManager ||
      state.selectedRole === CoreAPITypes.EUserCompanyRole.projectManager) &&
      !state.selectedGroupId);

  function resetStates(): void {
    dispatchTeams({ type: "SET_TEAM_NAME", teamName: "" });
    dispatchTeams({ type: "SET_MESSAGE", message: undefined });
    dispatchTeams({ type: "SET_DESCRIPTION", description: "" });
    dispatchTeams({ type: "SET_SELECTED_GROUP_ID", selectedGroupId: undefined });
    dispatchTeams({ type: "SET_SELECTED_ROLE", selectedRole: undefined });
    dispatchTeams({ type: "SET_SELECTED_MEMBERS_IDS", selectedMembersIds: [] });
  }

  /** Triggered when the create button is clicked, creates the group and closes the dialog */
  async function handleConfirm(): Promise<void> {
    dispatchTeams({ type: "SET_IS_LOADING", isLoading: true });

    trackEvent({
      name: TeamEvents.createNewTeam,
      props: { numberOfMembers: state.selectedMembersIds.length },
    });

    try {
      // Create Team
      const team = await dispatch(
        createTeam({
          coreApiClient,
          companyId,
          teamName: state.teamName.trim(),
          description: state.description.trim(),
        })
      ).unwrap();

      const addNewTeamMemberStore = convertToTeamMemberTypes(
        team,
        state.selectedRole
      );

      dispatch(setOne(addNewTeamMemberStore));

      await dispatch(
        updateMemberRoleInWorkspace({
          coreApiClient,
          companyId,
          role: state.selectedRole as SphereDashboardAPITypes.UpdateCompanyMemberRole,
          identity: team.identity,
        })
      );

      dispatchTeams({ type: "SET_TEAM_NAME", teamName: state.teamName.trim() });

      /** Team creation success message */
      const responseMessage = `${capitalizeFirstLetter(
        TEAM_DISPLAY_NAME
      )} created successfully`;

      /** Check if there are selected members to add; if so, proceed to add them, otherwise show a success message. */
      if (state.selectedMembersIds.length > 0) {
        await handleAddMembers(team.id, responseMessage);
      } else {
        showToast({
          type: "success",
          message: responseMessage,
        });
      }

      onCloseDialog();
      resetStates();
    } finally {
      dispatchTeams({ type: "SET_IS_LOADING", isLoading: false });
    }
  }

  /**
   * Add the members to the team
   * @param teamId The id of the team
   * @param responseMessage The message to be displayed after adding the members
   * @returns Promise<void>
   */
  async function handleAddMembers(
    teamId: string,
    responseMessage: string
  ): Promise<void> {
    if (!companyId) {
      throw new Error("companyId is required");
    }
    try {
      // Add members
      const response = await dispatch(
        addMembersToTeam({
          coreApiClient,
          companyId,
          teamId,
          members: state.selectedMembersIds,
        })
      ).unwrap();

      // Show response messages after adding members
      // Errors of creating team is handled by error slice
      const additionalMessages: string[] = [];

      if (response.errorData) {
        response.errorData.forEach((error: { message: string }) => {
          additionalMessages.push(error.message);
        });
      }

      // The status of the response
      // success: When all the invites go fine
      // warning: When some of the invites goes fine and some are failed
      // error: When all the invites are failed
      showToast({
        type: response.status,
        message: responseMessage,
        description: additionalMessages.map((message, index) => (
          <li key={index}>
            <var>{message}</var>
          </li>
        )),
      });
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMemberToTeam-${Date.now().toString()}`,
        title: `${responseMessage}. But couldn't invite the ${nounPluralize({
          shouldShowCounter: false,
          counter: state.selectedMembersIds.length,
          word: "member",
        })}`,
        error,
      });
    }
  }

  return (
    <FaroDialog
      title={`Create new ${TEAM_DISPLAY_NAME}`}
      open={isDialogOpen}
      confirmText="Create"
      isConfirmDisabled={isCreateDisabled}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onConfirm={handleConfirm}
      isConfirmLoading={state.isLoading}
      onClose={() => {
        onCloseDialog();
        resetStates();
      }}
    >
      <CreateTeamDialogContent state={state} dispatchTeams={dispatchTeams} />
    </FaroDialog>
  );
}
