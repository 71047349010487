import { Box, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { useMemo, useState } from "react";
import { FaroTextButton } from "@components/common/faro-text-button";

export interface VerticalTabsProps<T> {
  /** The label of a tab */
  label: T;

  /** The content of a tab */
  content: JSX.Element;
}

interface Props<T> {
  /** Default tab to show at first. If not provided, it will be the first tab */
  defaultTab?: T;

  /** List of available tabs */
  tabs: VerticalTabsProps<T>[];

  /** The title to show on the tab selection section. If not available, nothing is being shown */
  tabsSectionTitle?: string;

  /** Whether to show tab label as title in tab content section or not */
  shouldShowTabContentTitle?: boolean;
}

/**
 * A general component which generates vertical tabs.
 * The MUI vertical tabs are not used because it is unnecessary complicated for our purpose
 */
export function VerticalTabs<T extends string>({
  defaultTab,
  tabs,
  tabsSectionTitle,
  shouldShowTabContentTitle,
}: Props<T>): JSX.Element {
  const [selectedTabLabel, setSelectedTabLabel] = useState<T>(
    defaultTab ?? tabs[0].label
  );

  const selectedTabContent = useMemo(() => {
    return tabs.find((tab) => tab.label === selectedTabLabel)?.content;
  }, [selectedTabLabel, tabs]);

  return (
    <Grid container direction="row">
      <Grid item>
        {tabsSectionTitle && (
          <Box
            sx={{
              borderBottom: `1px solid ${sphereColors.gray200}`,
              textTransform: "uppercase",
              mb: "43px",
            }}
          >
            {tabsSectionTitle}
          </Box>
        )}

        {tabs.map((tab) => (
          <FaroTextButton
            key={tab.label}
            sx={{
              mb: "24px",
              pl: "0px",
              color:
                selectedTabLabel === tab.label
                  ? sphereColors.blue500
                  : sphereColors.black,
              fontSize: DEFAULT_TEXT_FONT_SIZE,
              fontWeight: selectedTabLabel === tab.label ? "bold" : "normal",
            }}
            onClick={() => setSelectedTabLabel(tab.label)}
          >
            {tab.label}
          </FaroTextButton>
        ))}
      </Grid>

      <Grid item sx={{ ml: "90px" }} flexGrow={1}>
        {tabsSectionTitle && (
          <Box
            sx={{
              borderBottom: `1px solid ${sphereColors.gray200}`,
              mb: "40px",
              height: "24px",
            }}
          >
            {shouldShowTabContentTitle ? selectedTabLabel : ""}
          </Box>
        )}

        {selectedTabContent}
      </Grid>
    </Grid>
  );
}
