import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  PublishedData,
  PublishedElsData,
} from "@pages/project-details/project-data-management/published-data/published-data-types";
import { allRegistrationRevisionsSelector } from "@store/capture-tree/capture-tree-selectors";
import { selectAllIElementsOfType } from "@faro-lotv/project-source";
import { isIElementElsDataSet } from "@custom-types/i-elements-type-guards";
import { CaptureApiClient, RegistrationState } from "@faro-lotv/service-wires";

/**
 * @returns The ELS dataset published from a registration of ELS scans.
 * The published ELS dataset corresponds to the last revision that was merged by the registration backend and
 * the associated IElementElsDataSet entity.
 */
const publishedElsDataSelector: (
  state: RootState
) => PublishedElsData | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const revisions = allRegistrationRevisionsSelector(state);
    const filteredRevisions = revisions.filter((revision) => {
      return (
        revision.state === RegistrationState.merged &&
        revision.createdByClient === CaptureApiClient.registrationBackend ||
        revision.createdByClient === CaptureApiClient.scene
      );
    });

    if (!filteredRevisions.length) {
      return;
    }

    const lastRevision = filteredRevisions.reduce((last, current) => {
      return new Date(current.createdAt) > new Date(last.createdAt)
        ? current
        : last;
    }, filteredRevisions[0]);

    const elsDataSets = selectAllIElementsOfType(isIElementElsDataSet)(state);

    return {
      ...lastRevision,
      element: elsDataSets.length ? elsDataSets[0] : undefined,
    };
  }
);

/**
 * @returns All PublishedData revisions.
 */
export const publishedDataSelector: (state: RootState) => PublishedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const publishedElsData = publishedElsDataSelector(state);
      // For now we only return the published ELS data
      return publishedElsData ? [publishedElsData] : [];
    }
  );
