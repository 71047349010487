import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { Box } from "@mui/material";
import { QueryParams } from "@router/route-params";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "@store/store-helper";
import { getLoggedInUser } from "@store/user/user-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { MainPageLayout } from "@components/main-page-layout";
import {
  VerticalTabs,
  VerticalTabsProps,
} from "@components/common/vertical-tabs";
import { AccountSecurityTab } from "@pages/account-preference/account-security-tab";
import { AccountPreferencesTab } from "@pages/account-preference/account-preferences-tab";

/** List all the tabs for account preference page */
export enum AccountPreferenceTabs {
  accountAndSecurity = "Account & Security",
  preferences = "Preferences",
}

/** Content for the account preference page */
export function AccountPreferencePage(): JSX.Element {
  const { setLoadingSpinner, isLoadingSpinnerShowing } = useLoadingSpinner();
  const { navigateToRoot } = useAppNavigation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  // Accessing alpha flag from selector is not available at this state. This is because the store is not set up yet.
  const enableAlphaParam = searchParams.get(QueryParams.enableAlpha);

  // The loading spinner is set to true when the sphere dashboard is being initialized. It will set to false in SdbCompanyLoader.
  // However, this component is mounted independent of SdbCompanyLoader. As a result, the loading spinner will be shown forever.
  // Therefore, we need to set the loading spinner to false when this component is mounted.
  // TODO: Check if this is the correct approach. https://faro01.atlassian.net/browse/ST-2825
  useEffect(() => {
    if (isLoadingSpinnerShowing) {
      setLoadingSpinner(false);
    }
  }, [isLoadingSpinnerShowing, setLoadingSpinner]);

  // Fetch logged in user
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    dispatch(getLoggedInUser({ coreApiClient }));
  }, [coreApiClient, dispatch]);

  /** Navigate to root page if the alpha flag is not available yet */
  if (enableAlphaParam !== "true") {
    navigateToRoot();
  }

  /**
   * Clicking on close will navigate to the previous page if user has opened the page from the app
   * If the user has opened the page by pasting the link, it will navigate to the root page
   */
  function onCloseButtonClick(): void {
    if (location.state === "in-app-navigation") {
      navigate(-1);
    } else {
      navigateToRoot();
    }
  }

  const tabs: VerticalTabsProps<AccountPreferenceTabs>[] = useMemo(() => {
    return [
      {
        label: AccountPreferenceTabs.accountAndSecurity,
        content: <AccountSecurityTab />,
      },
      {
        label: AccountPreferenceTabs.preferences,
        content: <AccountPreferencesTab />,
      },
    ];
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <MainPageLayout
        shouldShowSidebar={false}
        shouldHideOpenButton={true}
        closeButton={{
          alignment: "left",
          onClick: onCloseButtonClick,
          shouldShowText: true,
        }}
      >
        <VerticalTabs
          tabs={tabs}
          tabsSectionTitle="General"
          shouldShowTabContentTitle={true}
        />
      </MainPageLayout>
    </Box>
  );
}
